<template>
    <div>
        <title-data :TitleInfo="TitleInfo"></title-data>
        <div style="display:flex;">
            <div style="width:50%;">
                <div class="echarts-main" id="DoughnutChart"></div>
            </div>
            <div v-if="pageState" style="width:50%;padding-top:40px;">
                <div class="text-main">
                    <div class="text-name"><span class="color-box bg-1"></span>{{datas.approval.name}}</div>
                    <div class="numbers">{{datas.approval.value}}</div>
                    <div class="ratios">{{datas.approval.ratio}}</div>
                </div>
                <div class="text-main">
                    <div class="text-name"><span class="color-box bg-2"></span>{{datas.order.name}}</div>
                    <div class="numbers">{{datas.order.value}}</div>
                    <div class="ratios">{{datas.order.ratio}}</div>
                </div>
                <div class="text-main">
                    <div class="text-name"><span class="color-box bg-3"></span>{{datas.business.name}}</div>
                    <div class="numbers">{{datas.business.value}}</div>
                    <div class="ratios">{{datas.business.ratio}}</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//图表
import * as echarts from 'echarts'
//调用接口
import { classifyEvent } from '../../api/CqBiApi'

export default{
    components: {
        TitleData
    },
    data() {
        return {
            pageState:false,
            TitleInfo:{title:'事件分布比例',type:0,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
            datas:{
                approval:{name:'审批',ratio:'0%',value:0},
                business:{name:'业务',ratio:'0%',value:0},
                order:{name:'订单',ratio:'0%',value:0}
            }
        }
    },
    created() {
        
    },
    mounted() {
        this.DoughnutChart = echarts.init(document.getElementById('DoughnutChart'), null, {renderer: 'svg'});
        this.get_classify_event()
    },
    methods: {
        get_classify_event(){
            classifyEvent().then(res => {
                if(res.code == 1){
                    this.pageState = true
                    this.datas = res.data
                    this.get_info_echarts()
                }
            })
        },
        get_info_echarts(){
            let option = {
                color:['#00a3f0','#8fff68','#ffdf00'],
                tooltip: {trigger: 'item',formatter: '{b} : {c} ({d}%)',confine: true},
                series: [{
                    type: 'pie',
                    radius: ['50%', '76%'],
                    avoidLabelOverlap: false,
                    label: {show: false,position: 'center'},
                    data: [
                        { value: this.datas.business.value,name:this.datas.business.name},
                        { value: this.datas.order.value,name:this.datas.order.name},
                        { value: this.datas.approval.value,name:this.datas.approval.name}
                    ]
                }]
            };
            option && this.DoughnutChart.setOption(option);
        },
        
    }
}
</script>

<style scoped>
.echarts-main{height:190px;margin:5px 10px;}
.text-main{display:flex;line-height:40px;}
.text-name{color:#ffffff;font-size:14px;margin-right:15px;}
.color-box{display:inline-block;width:14px;height:12px;border-radius:2px;margin-right:10px;}
.bg-1{background-color:#ffdf00;}
.bg-2{background-color:#8fff68;}
.bg-3{background-color:#00a3f0;}
.numbers{color:#00deff;margin-right:15px;font-size:14px;}
.ratios{color:#00deff;font-size:14px;}
</style>