<template>
    <div>
        <title-data :TitleInfo="TitleInfo"></title-data>
        <div style="display:flex;">
            <div style="width:50%;">
                <div style="" class="echarts-main" id="SunburstVisualMap"></div>
            </div>
            <div v-if="pageState" style="width:50%;padding-top:17.25px;">
                <div class="text-main" style="line-height:25px;margin-bottom:15px;">
                    <div class="text-name">
                        <span class="color-box bg-1"></span>{{datas.app.name}}<span class="separate">|</span><span class="ratios">{{datas.app.ratio}}</span>
                    </div>
                    <div class="text-name">
                        <span class="color-box"></span>{{datas.IOSapp.name}}<span class="separate">|</span><span class="ratios">{{datas.IOSapp.ratio}}</span>
                    </div>
                    <div class="text-name">
                        <span class="color-box"></span>{{datas.Androidapp.name}}<span class="separate">|</span><span class="ratios">{{datas.Androidapp.ratio}}</span>
                    </div>
                </div>
                <div class="text-main" style="line-height:25px;">
                    <div class="text-name">
                        <span class="color-box bg-2"></span>{{datas.applets.name}}<span class="separate">|</span><span class="ratios">{{datas.applets.ratio}}</span>
                    </div>
                    <div class="text-name">
                        <span class="color-box"></span>{{datas.IOSapplets.name}}<span class="separate">|</span><span class="ratios">{{datas.IOSapplets.ratio}}</span>
                    </div>
                    <div class="text-name">
                        <span class="color-box"></span>{{datas.Androidapplets.name}}<span class="separate">|</span><span class="ratios">{{datas.Androidapplets.ratio}}</span>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//图表
import * as echarts from 'echarts'
//调用接口
import { classifyUser } from '../../api/CqBiApi'

export default{
    components: {
        TitleData
    },
    data() {
        return {
            pageState:false,
            TitleInfo:{title:'系统使用人数分布及应用市场分布',type:0,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
            datas:{
                app:{name:'长勤APP',ratio:'0%',value:0},
                IOSapp:{name:'IOS',ratio:'0%',value:0},
                Androidapp:{name:'Android',ratio:'0%',value:0},
                applets:{name:'长勤小程序',ratio:'0%',value:0},
                IOSapplets:{name:'IOS',ratio:'0%',value:0},
                Androidapplets:{name:'Android',ratio:'0%',value:0},
            },
        }
    },
    created() {
        
    },
    mounted() {
        this.SunburstVisualMap = echarts.init(document.getElementById('SunburstVisualMap'), null, {renderer: 'svg'});
        this.get_classify_user()
    },
    methods: {
        get_classify_user(){
            classifyUser().then(res => {
                if(res.code == 1){
                    this.pageState = true
                    this.datas = res.data
                    this.get_info_echarts()
                }
            })
        },
        get_info_echarts(){
            let option = {
                tooltip: {trigger: 'item',formatter: '{b} : {c} ({d}%)',confine: true},
                series: [{
                    type: 'pie',
                    radius: ['50%', '76%'],
                    avoidLabelOverlap: false,
                    label: {show: false,position: 'center'},
                    itemStyle: {borderColor: '#fff',borderWidth: 1},
                    data: [
                        {name:'小程序-IOS',value:this.datas.IOSapplets.value,itemStyle: {color: '#00baff'}},
                        {name:'小程序-Android',value:this.datas.Androidapplets.value,itemStyle: {color: '#00baff'}},
                        {name:'长勤APP-IOS',value:this.datas.IOSapp.value,itemStyle: {color: '#ff652a'}},
                        {name:'长勤APP-Android',value:this.datas.Androidapp.value,itemStyle: {color: '#ff652a'}}
                    ]
                }]
                //放弃使用
                /*tooltip: {
                    formatter: function (value) {
                        if(value != undefined && value != null && value != ''){
                            return value.data.nameText +"："+ value.data.ratioText
                        }else{
                            return ''
                        }
                    }
                },*/
                /*series: {
                    type: 'sunburst',
                    label: {rotate: 'radial'},
                    clickable:false,
                    levels: [{},{r0: '0%',r: '0%'},{r0: '50%',r: '75%',itemStyle: {borderWidth: 1}}],
                    data: [
                        {children: [
                            {nameText:'小程序-IOS',ratioText:this.datas.IOSapplets.ratio,value: this.datas.IOSapplets.value,itemStyle: {color: '#00baff'}},
                            {nameText:'小程序-Android',ratioText:this.datas.Androidapplets.ratio,value: this.datas.Androidapplets.value,itemStyle: {color: '#00baff'}}
                        ]},
                        {children: [
                            {nameText:'长勤APP-IOS',ratioText:this.datas.IOSapp.ratio,value: this.datas.IOSapp.value,itemStyle: {color: '#ff652a'}},
                            {nameText:'长勤APP-Android',ratioText:this.datas.Androidapp.ratio,value: this.datas.Androidapp.value,itemStyle: {color: '#ff652a'}}
                        ]}
                    ]
                }*/
            };
            option && this.SunburstVisualMap.setOption(option);
        }
    }
}
</script>

<style scoped>
.echarts-main{height:190px;margin:5px 10px;}
.text-name{color:#ffffff;font-size:14px;margin-right:20px;}
.separate{margin:0 10px;}
.color-box{display:inline-block;width:14px;height:12px;border-radius:2px;margin-right:10px;}
.bg-1{background-color:#ff652a;}
.bg-2{background-color:#00a3f0;}
.ratios{color:#00deff;}
</style>