<template>
  <div class="wraper">
    <scale-box>
      <div class="header"><!-- 头部 -->
        <!-- 时间组件 -->
        <now-date-time></now-date-time>
      </div>

      <!-- 整体内容容器 -->
      <div class="container-main">
        <!-- 左边容器 -->
        <div class="container-box-a">
          <div class="container mb15">
            <!-- 支撑订单部分--双线折线图 -->
            <div class="assembly"><stacked-line-chart></stacked-line-chart></div>
            <!-- 营收部分--双柱柱状图 -->
            <div class="assembly"><simple-example-of-dataset></simple-example-of-dataset></div>
          </div>
          <div class="container">
            <!-- TOP10订单地区分布--单柱柱状图 -->
            <div class="assembly"><basic-bar></basic-bar></div>
            <!-- 用户实时在线情况--面积图 -->
            <div class="assembly"><gradient-stacked-area-chart></gradient-stacked-area-chart></div>
          </div>
        </div>

        <!-- 中间容器 -->
        <div class="container-box-b">
          <!-- 数据概览--统计与地图 -->
          <div class="assembly-b"><statistics></statistics></div>
          <!-- TOP10城市用户人数排行--单柱柱状图(样式2(3D)) -->
          <div class="assembly-c"><basic-bar-two></basic-bar-two></div>
        </div>

        <!-- 右边容器 -->
        <div class="container-box-c">
          <div class="container mb15">
            <!-- 系统使用人数分布及应用市场分布--饼图（视觉） -->
            <div class="assembly"><sunburst-visual-map></sunburst-visual-map></div>
            <!-- 事件分布比例--饼图 -->
            <div class="assembly"><doughnut-chart></doughnut-chart></div>
          </div>
          <div class="container">
            <!-- 累计事件--线型图 -->
            <div class="assembly"><smoothed-line-chart></smoothed-line-chart></div>
            <!-- 实时数据--列表滚动 -->
            <div class="assembly"><seamless-scroll></seamless-scroll></div>
          </div>
        </div>

      </div>

      <div class="footer"><!-- 底部版权 --></div>
    </scale-box>
  </div>
</template>

<script>
//主缩放容器组件
import ScaleBox from "../../components/SacleBox/index.vue";
//时间组件
import NowDateTime from "../../components/TimeData/index.vue";
//堆叠线形图
import StackedLineChart from "../../components/StackedLineChart/index.vue";
//柱状图数据级
import SimpleExampleOfDataset from "../../components/SimpleExampleOfDataset/index.vue";
//柱状图单数据
import BasicBar from "../../components/BasicBar/index.vue";
//面积图
import GradientStackedAreaChart from "../../components/GradientStackedAreaChart/index.vue";
//统计和地图
import Statistics from "../../components/Statistics/index.vue";
//柱状图单数据(3D)
import BasicBarTwo from "../../components/BasicBarTwo/index.vue";
//饼图（视觉(空心)）
import SunburstVisualMap from "../../components/SunburstVisualMap/index.vue";
//饼图（空心）
import DoughnutChart from "../../components/DoughnutChart/index.vue";
//线型图
import SmoothedLineChart from "../../components/SmoothedLineChart/index.vue";
//列表滚动
import SeamlessScroll from "../../components/SeamlessScroll/index.vue";

export default {
  components: {
    ScaleBox,
    NowDateTime,
    StackedLineChart,
    SimpleExampleOfDataset,
    BasicBar,
    GradientStackedAreaChart,
    Statistics,
    BasicBarTwo,
    SunburstVisualMap,
    DoughnutChart,
    SmoothedLineChart,
    SeamlessScroll,
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
      
  },
}
</script>

<style scoped>
.mb15{margin-bottom:15px;}
.wraper{width:100vw;height:100vh;background:url(../../assets/images/bg.png);background-size:100% 100%;}
.header{width:1920px;height:62px;background:url(../../assets/images/header.png);position: absolute;top:0;left:0;}
.container-main{display:flex;position:absolute;top:60px;left:0;width:1920px;height:980px;}
.container-box-a{flex:1.5;margin-left:15px;}
.container-box-b{flex:3;margin-left:15px;margin-right:15px;}
.container-box-c{flex:1.5;margin-right:15px;}
.container{width:100%;height:482.5px;background:url(../../assets/images/container-bg-a.png); background-size:100% 100%;}
.assembly{width:100%;height:241.25px;}
.assembly-b{width:100%;height:708.75px;margin-bottom:15px;margin-top:15px;background:url(../../assets/images/container-bg-b.png); background-size:100% 100%;}
.assembly-c{width:100%;height:241.25px;background:url(../../assets/images/container-bg-c.png); background-size:100% 100%;}
.footer{width:1920px;height:33px;background:url(../../assets/images/footer.png);position: absolute;left:0;bottom:4px;}
</style>