<template>
    <div>
        <title-data :TitleInfo="TitleInfo" @ChartDateType="ChartDateType"></title-data>
        <div class="echarts-main" id="SimpleExampleOfDataset"></div>
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//图表
import * as echarts from 'echarts'
//调用接口
import { revenueOrder } from '../../api/CqBiApi'

export default{
    components: {
        TitleData
    },
    data() {
        return {
            TitleInfo:{title:'营收部分',type:1,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
            queryData:'month',
        }
    },
    created() {
        
    },
    mounted() {
        this.SimpleExampleOfDataset = echarts.init(document.getElementById('SimpleExampleOfDataset'), null, {renderer: 'svg'});
        this.get_revenue_order()
    },
    methods: {
        get_revenue_order(){
            revenueOrder({
                data: {
                    tab:this.queryData
                }
            }).then(res => {
                if(res.code == 1){
                    this.get_info_echarts(res.data)
                }
            })
        },
        get_info_echarts(data){
            let option = {
                color: [
                    {type: 'linear',x: 0,y: 0,x2: 0,y2: 1,colorStops: [{ offset: 0, color: '#00bbfe' },{ offset: 1, color: '#0054d3' }]},
                    {type: 'linear',x: 0,y: 0,x2: 0,y2: 1,colorStops: [{ offset: 0, color: '#fd9a1e' },{ offset: 1, color: '#d34a92' }]}
                ],
                legend: {x:'right',textStyle: {color: '#ffffff'}, data: ['盈锐供应链', '智慧差旅']},
                tooltip: {trigger: 'axis'},
                xAxis: { type: 'category' ,axisLabel: {color: "#8a9ecf",show: true},data: data.xaxis},
                yAxis: {name: data.unit,splitLine: {show: false},nameTextStyle: {color: '#8a9ecf'},axisLabel: {color: "#8a9ecf",show: true}},
                grid: {top: '35px',left: '10px',right: '10px',bottom: '15px',containLabel: true},
                series: [
                    {name: '盈锐供应链',type: 'bar',data: data.yingrui},
                    {name: '智慧差旅',type: 'bar',data: data.travel}
                ]
            };
            option && this.SimpleExampleOfDataset.setOption(option);
        },
        //切换时间选项的回调方法
        ChartDateType(info){
            this.queryData = info.typeName
            this.get_revenue_order()
        }
    }
}
</script>

<style scoped>
.echarts-main{height:190px;margin:5px 10px;}
</style>