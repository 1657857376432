import req from "../methods/req"

//支撑订单部份
export const braceOrder = function (data){
    return req({url:'/index/order/braceOrder',...data})
}

//营收部份
export const revenueOrder = function (data){
    return req({url:'/index/order/revenueOrder',...data})
}

//top10订单地区分布
export const regionOrder = function (data){
    return req({url:'/index/order/regionOrder',...data})
}

//用户实时在线情况
export const userOnline = function (data){
    return req({url:'/index/user/userOnline',...data})
}

//数据概览
export const overview = function (data){
    return req({url:'/index/index/overview',...data})
}

//top10城市用户人数排行
export const regionUser = function (data){
    return req({url:'/index/user/regionUser',...data})
}

//系统使用人数分布及应用市场分布
export const classifyUser = function (data){
    return req({url:'/index/user/classifyUser',...data})
}

//事件分布比例
export const classifyEvent = function (data){
    return req({url:'/index/event/classifyEvent',...data})
}

//累计事件
export const allEvent = function (data){
    return req({url:'/index/event/allEvent',...data})
}

//实时数据
export const realEvent = function (data){
    return req({url:'/index/event/realEvent',...data})
}

//客户地图
export const mapCustomer = function (data){
    return req({url:'/index/customer/mapCustomer',...data})
}

//top10客户地区分部
export const regionCustomer = function (data){
    return req({url:'/index/customer/regionCustomer',...data})
}




