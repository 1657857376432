import axios from 'axios'
import config from './config'

function req({url = '',data = {},type = 'post'}){
    return new Promise((resolve, reject) => {
        url = config.host + url + getUrlKey();
        let headers = {
            'Content-Type': 'application/json',
        }
        axios({method: type,url: url,headers,data: data,}).then(res => {
            resolve(res.data)
        }).catch(e => {
            reject(e)
        }).finally(() => {
            
        })
    })
}

//获取网址参数
function getUrlKey(){
    var url = window.location.href ;             //获取当前url
    var dz_url = url.split('#')[1];                //获取#/之后的字符串
    var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
    if(cs != undefined && cs != null && cs != ''){
        return '?'+cs
    }else{
        return '?code=1'
    }  
}

export default req;