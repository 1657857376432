<template>
    <div :class="pageState?'page-main':''">
        <div v-if="pageState" class="title-main">
            <div class="title">TOP10客户地区分布</div>
            <div style="position:absolute;color:#8a9ecf;font-size:12px;top:15px;right:35px;">单位(人)</div>
        </div>
        <div style="width:240px;height:360px;" id="SimpleEncode"></div>
    </div>
</template>

<script>
//图表
import * as echarts from 'echarts'
//调用接口
import { regionCustomer } from '../../api/CqBiApi'

export default{
    components: {
        
    },
    data() {
        return {
            pageState:false,
            dataList:{},
        }
    },
    created() {
        
    },
    mounted() {
        this.SimpleEncode = echarts.init(document.getElementById('SimpleEncode'), null, {renderer: 'svg'});
        this.get_region_customer()
    },
    methods: {
        get_region_customer(){
            regionCustomer().then(res => {
                if(res.code == 1){
                    this.pageState = true
                    let datas = res.data
                    //获取总数量
                    let countNumber = 0
                    for(var j = 0; j < datas.length; j++){
                        countNumber = countNumber + parseInt(datas[j].num)
                    }
                    //处理数据
                    let list = []
                    list.push(['num', 'region','ratio'])
                    for(var i = 0; i < datas.length; i++){
                        let ratio = 0
                        if(countNumber > 0){
                            ratio = parseFloat((datas[i].num/countNumber)*100).toFixed(2) 
                        }
                        list.push([datas[i].num,datas[i].region,ratio])
                    }
                    this.dataList = list
                    this.get_info_echarts()
                }
            })
        },
        get_info_echarts(){
            let that = this
            let option = {
                tooltip: {trigger: 'item',
                    formatter: function (value){
                        return value.data[1] +"："+ value.data[0] +" ("+ value.data[2] +"%)"
                    }
                },
                color: [{type: 'linear',x: 0,y: 0,x2: 1,y2: 0,colorStops: [{ offset: 0, color: '#20e199' },{ offset: 1, color: '#33ddf8' }]}],
                dataset: [{source: that.dataList},{transform: {type: 'sort',config: { dimension: 'num', order: 'asc' }}}],
                grid: {top: '10px',left: '10px',right: '30px',bottom: '20px',containLabel: true},
                xAxis: {position: 'top',splitLine: {show: false},axisLabel: {showMinLabel: true,showMaxLabel: true,color: "#8a9ecf",show: true,
                    formatter: function(value,index){
                        if(index == 0){
                            return value;
                        }else{
                            if (parseInt(index)%2==1){
                                return value;
                            }else{
                                return '';
                            }
                        }
                    }
                },boundaryGap: false},
                yAxis: {type: 'category',axisLabel: {color: "#ffffff",show: true}},
                series: {
                    type: 'bar',
                    encode: { x: 'num', y: 'region' },
                    datasetIndex: 1,
                    barWidth: 8,itemStyle: {borderRadius: [0,10,10,0]},label: {show: true,position: 'right',color:'#00deff'}
                }
            };
            option && this.SimpleEncode.setOption(option);
        }
    }
}
</script>

<style scoped>
.page-main{width:100%;height:100%;background:rgba(0, 29, 122, .5);}

.title-main{
        width:100%;
        position:relative;
    }
.title{
        height:40px;
        line-height:45px;
        font-size:16px;
        padding-left:10px;
        background:linear-gradient(to left, #0072FF 0%,#00EAFF 50%, #0072FF 100%);
        color:transparent;
        display:inline-block;
        -webkit-background-clip:text;
    }
</style>