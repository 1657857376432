<template>
    <div>
        <title-data :TitleInfo="TitleInfo"></title-data>
        <!-- 滚动列表 -->
        <div class="InfiniteScroll">
            <div class="scroll_parent_box" @mouseenter="mEnter" @mouseleave="mLeave">
                <div class="scroll_list" :style="{ transform: `translate(0px,-${scrollTop}px)` }">
                    <div ref="scrollItemBox">
                        <div class="scroll_item" v-for="(item, index) in listData" :key="index">
                            <div class="times">{{item.create_time}}</div>
                            <div :class="index % 2 == 0 ? 'contents contents_bg' : 'contents'">
                                {{item.username}}&nbsp;{{item.operation}}&nbsp;{{item.type_name}}&nbsp;{{item.describe}}
                            </div>
                        </div>
                    </div>
                    <div v-html="copyHtml"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//调用接口
import { realEvent } from '../../api/CqBiApi'

let timer = null

export default{
    components: {
        TitleData,
    },
    data() {
        return {
            TitleInfo:{title:'实时数据',type:0,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
            scrollTop: 0, //列表滚动高度
            speed: 20, //滚动的速度
            listData: '', //表示有多少个列表项
            copyHtml: '', //复制多一份防止滚动到后面出现空白
            startScrollState:true,  //开启滚动状态
        }
    },
    created() {
        
    },
    mounted() {
        this.get_real_event_list()
    },
    methods: {
        get_real_event_list(){
            realEvent().then(res => {
                if(res.code == 1){
                    this.listData = res.data
                    // 如果列表数据是异步获取的，记得初始化在获取数据后再调用
                    this.initScroll()
                }
            })
            let that = this
            setTimeout(function(){
                that.get_real_event_list()
            },3000)
        },
        initScroll() {
            this.$nextTick(() => {
                this.copyHtml = this.$refs.scrollItemBox.innerHTML
                this.startScroll()
            })
        },
        // 鼠标移入停止滚动
        mEnter() {
            clearInterval(timer);
        },
        // 鼠标移出继续滚动
        mLeave() {
            this.startScrollState = true
            this.startScroll()
        },
        // 开始滚动
        startScroll() {
            if(this.startScrollState){
                this.startScrollState = false
                timer = setInterval(this.scroll, this.speed);
            }
        },
        // 滚动处理方法
        scroll() {
            this.scrollTop++
            // 获取需要滚动的盒子的高度
            let scrollItemBox = this.$refs.scrollItemBox.offsetHeight
            // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
            if (this.scrollTop >= scrollItemBox) {
                this.scrollTop = 0
            }
        }
    }
}
</script>

<style scoped>
.InfiniteScroll {box-sizing: border-box;width:460px;padding:10px 15px;color:#ffffff;}
.scroll_parent_box {width: 100%;height: 175px;overflow: hidden;box-sizing: border-box;padding: 0 0px;position: relative;}
.line_box{position: absolute;width:2px;height:140px;top:18px;left:75px;background:#50fcfc;}
.scroll_list {transition: all 0ms ease-in 0s;}
.scroll_item {height: 35px;line-height: 35px;font-size: 14px;display:flex;}
.times{color:#8a9ecf;padding-left:5px;width:70px;}
.img_box{margin-left:10px;}
.contents{width:340px;margin-left:15px;padding:0 5px 0 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.contents_bg{background:rgba(24, 54, 145, .4);}
</style>