<template>
    <div>
        <title-data :TitleInfo="TitleInfo"></title-data>
        <div class="echarts-main" id="BasicBarTwoDom"></div>
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//图表
import * as echarts from 'echarts'
//调用接口
import { regionUser } from '../../api/CqBiApi'

export default{
    components: {
        TitleData
    },
    data() {
        return {
            TitleInfo:{title:'TOP 10 城市用户人数排行',type:0,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
        }
    },
    created() {
        
    },
    mounted() {
        this.BasicBarTwoDom = echarts.init(document.getElementById('BasicBarTwoDom'), null, {renderer: 'svg'});
        this.get_region_user()
    },
    methods: {
        get_region_user(){
            regionUser().then(res => {
                if(res.code == 1){
                    let datas = res.data
                    let regions = []
                    let nums = []
                    for(var i = 0; i < datas.length; i++){
                        regions.push(datas[i].region)
                        nums.push(datas[i].num)
                    }
                    if(regions && nums){
                        this.get_info_echarts(regions,nums)
                    }
                }
            })
        },
        get_info_echarts(regions,nums){
            let option = {
                tooltip: {},
                grid: {top: '35px',left: '10px',right: '10px',bottom: '15px',containLabel: true},
                xAxis: {type: 'category',axisLabel: {color: "#8a9ecf",show: true},data: regions},
                yAxis: {name: '人',splitLine: {show: false},nameTextStyle: {color: '#8a9ecf'},axisLabel: {color: "#8a9ecf",show: true}},
                series: [
                    {
                        name: "",
                        type: "bar",
                        barWidth: 40,
                        barCategoryGap: 12,
                        itemStyle: {color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: "#f59e22",},{offset: 1,color: "#04dcfa",}])},
                        data: nums
                    },
                    {
                        name: "",
                        type: "pictorialBar",
                        symbolSize: [40, 8],
                        symbolOffset: [0, -3],
                        z: 12,
                        itemStyle: { color: "#d07000" },
                        symbolPosition: "end",
                        label: {show: true,position: 'top',color:'#00deff'},  //显示总数 rotate:45 => 倾斜角度
                        data: nums
                    }
                ]
            };
            option && this.BasicBarTwoDom.setOption(option);
        },
        
    }
}
</script>

<style scoped>
.echarts-main{height:190px;margin:5px 10px;}
</style>