<template>
  <div class="main-box">
    <span class="date-main">{{nowDate}}</span>
    <span class="week-main">{{nowWeek}}</span>
    <span class="time-main">{{nowTime}}</span>
  </div>
</template>

<script>
export default {
    data() {
        return {
        timer: null,
        nowWeek: "",
        nowDate: "",
        nowTime: "",
        };
    },
    mounted() {
        var _this = this;
        this.timer = setInterval(() => {
            _this.setNowTimes();
        }, 1000);
    },
    methods: {
        setNowTimes() {
            let myDate = new Date();
            let wk = myDate.getDay();
            let yy = String(myDate.getFullYear());
            let mm = myDate.getMonth() + 1;
            mm = mm < 10 ? '0'+mm : mm
            let dd = String(myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate());
            let hou = String(myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours());
            let min = String(myDate.getMinutes() < 10 ? "0" + myDate.getMinutes() : myDate.getMinutes());
            let sec = String(myDate.getSeconds() < 10 ? "0" + myDate.getSeconds(): myDate.getSeconds());
            let weeks = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六",];
            let week = weeks[wk];
            this.nowDate = yy + "/" + mm + "/" + dd
            this.nowTime = hou + ":" + min + ":" + sec;
            this.nowWeek = week;
        },
    },
};
</script>

<style scoped>
.main-box{
    color:#39d6fe;
    font-size:14px;
    position: absolute;
    top:16px;
    right:15px;
}

.date-main{
    margin-right:10px;
}

.week-main{
    margin-right:15px;
}
.time-main{
    width:60px;
    display:inline-block;
}
</style>
