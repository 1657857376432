<template>
    <div>
        <title-data :TitleInfo="TitleInfo" @ChartDateType="ChartDateType"></title-data>
        <div class="echarts-main" id="StackedLineChart"></div>
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//图表
import * as echarts from 'echarts'
//调用接口
import { braceOrder } from '../../api/CqBiApi'

export default{
    components: {
        TitleData
    },
    data() {
        return {
            TitleInfo:{title:'支撑订单部分',type:1,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
            queryData:'month',
        }
    },
    created() {
        
    },
    mounted() {
        this.StackedLineChart = echarts.init(document.getElementById('StackedLineChart'), null, {renderer: 'svg'});
        this.get_brace_order()
    },
    methods: {
        get_brace_order(){
            braceOrder({
                data: {
                    tab:this.queryData
                }
            }).then(res => {
                if(res.code == 1){
                    let datas = res.data.data
                    let numbers = []
                    let orders = []
                    let times = []
                    for(var i = 0; i < datas.length; i++){
                        numbers.push(datas[i].num)
                        orders.push(datas[i].price)
                        times.push(datas[i].xaxis)
                    }
                    if(numbers && orders && times){
                        this.get_info_echarts(times,numbers,orders,res.data.unit)
                    }
                }
            })
        },
        get_info_echarts(times,numbers,orders,units){
            let option = {
                color: ['#01f8cd','#cb4eff'],
                tooltip: {trigger: 'axis'},
                legend: {x:'right',textStyle: {color: '#ffffff'},data: ['支撑量', '订单额']},
                xAxis: {type: 'category',boundaryGap: false,axisLabel: {color: "#8a9ecf",show: true},data: times},
                yAxis: {type: 'value',name: units,splitLine: {show:true,lineStyle:{color: ['#10427d']}},nameTextStyle: {color: '#8a9ecf'},axisLabel: {color: "#8a9ecf",show: true}},
                grid: {top: '35px',left: '10px',right: '10px',bottom: '15px',containLabel: true},
                series: [
                    {name:'支撑量',type:'line',smooth: true,data: numbers},
                    {name:'订单额',type:'line',smooth: true,data: orders}
                ]
            };
            option && this.StackedLineChart.setOption(option);
        },
        //切换时间选项的回调方法
        ChartDateType(info){
            this.queryData = info.typeName
            this.get_brace_order()
        }
    }
}
</script>


<style scoped>
.echarts-main{height:190px;margin:5px 10px;}
</style>