
const host_state = true    //true为正式，false为测试

// 测试接口
let host = 'http://cq-bi.52shanglu.com';

if(host_state){
    // 正式接口
    host = 'http://bi.changqinos.com';
}

export default {
    host,
}