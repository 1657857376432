<template>
    <div style="padding:2px;">
        <div class="title-main">
            <img src="../../assets/images/title-bg.png" class="title-img" />
            <div class="title">{{TitleInfo.title}}</div>
            <div class="title-box" v-if="activitState > 0">
                <div v-for="(item,index) in dateInfos" :key="index" :class="item.type == activitData ? 'title-but title-but-activit' : 'title-but'" @click="activitFun(item)">{{item.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    emits: ["ChartDateType"],
    props: {
        TitleInfo:{
            type: Object,
            default () {
                return {

                }
            }
        },
    },
    data() {
        return {
            dateInfos:[
                {type:1,title:'近30天',value:30,typeName:'month'},
                {type:2,title:'近7天',value:7,typeName:'week'},
                {type:3,title:'近24时',value:24,typeName:'day'}
            ],
            activitData:1,
            activitState:0
        }
    },
    created() {
        this.dateActivitInfo()
    },
    mounted() {
        
    },
    methods: {
        dateActivitInfo(){
            if(this.TitleInfo.type == 1){
                this.activitState = 1
                this.activitData = this.TitleInfo.value
            }
        },
        activitFun(info){
            this.activitData = info.type
            this.$emit('ChartDateType',info)
        },
    }
}
</script>


<style scoped>
    
    .title-main{
        width:100%;
        background-image:linear-gradient(90deg,#003a71 0%,rgba(0, 45, 87, 0.29) 100%);
        position:relative;
    }
    .title-img{
        margin-bottom:-3px;
        margin-right:10px;
        margin-left:1px;
    }
    .title{
        height:40px;
        line-height:40px;
        font-size:18px;
        background:linear-gradient(to left, #0072FF 0%,#00EAFF 50%, #0072FF 100%);
        color:transparent;
        display:inline-block;
        -webkit-background-clip:text;
    }
    .title-box{
        position:absolute;
        display:flex;
        right:15px;
        top:10px;
        font-size:13px;
        color:#4d74cb;
    }
    .title-but{
        border:1px #183691 solid;
        padding:0px 7px;
        height:19px;
        margin-left:3px;
    }
    .title-but-activit{
        color:#50fcfc;
        border:1px #1082c3 solid;
    }
</style>