<template>
    <div>
        <title-data :TitleInfo="TitleInfo"></title-data>
        <div class="statistics-main"> 
            <div class="statistics-box" v-for="(item,index) in dataList" :key="index">
                <div>{{item.name}}</div>
                <div class="number">{{item.value}}</div>
                <div v-if="item.ratio != 0">
                    <div class="rate" v-if="item.ratio > 0">同比<span class="rate-text-red">{{item.ratio}}%↑</span></div>
                    <div class="rate" v-if="item.ratio < 0">同比<span class="rate-text-green">{{item.ratio}}%↓</span></div>
                </div>
            </div>
        </div>
        <!-- 地图和浮动组件 -->
        <div class="map-main">
            <!-- 地图组件 -->
            <div><map-box></map-box></div>
            <!-- top10客户地区排行 -->
            <div class="ranking-echarts"><simple-encode></simple-encode></div>
        </div>
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//地图组件
import MapBox from "../MapBox/index.vue";
//图表组件
import SimpleEncode from "../SimpleEncode/index.vue";
//调用接口
import { overview } from '../../api/CqBiApi'

export default {
  components: {
    TitleData,
    MapBox,
    SimpleEncode
  },
  data() {
    return {
      TitleInfo:{title:'数据概览',type:0,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
      dataList:[]
    }
  },
  created() {
    
  },
  mounted() {
      this.get_overview()
  },
  methods: {
    get_overview(){
        overview().then(res => {
            if(res.code == 1){
                this.dataList = res.data
            }
        })
        let that = this
        setTimeout(function(){
            that.get_overview()
        },3000)
    },
  }
}
</script>

<style scoped>
.statistics-main{width:auto;height:185px;display:flex;flex-wrap: wrap;padding-left:20px;}
.statistics-box{width:calc(25% - 12px);height:80px;margin-bottom:5px;margin-right:10px;color:#ffffff;text-align: center;font-size:14px;padding-top:8px;}
.number{font-size:28px;font-weight:700;color:#15fdff;}
.rate{font-size:12px;}
.rate-text-red{color:#ff3b59;margin-left:3px;}
.rate-text-green{color:#5affa3;margin-left:3px;}
.map-main{position: relative;height:470px;}
.ranking-echarts{position: absolute;width:240px;height:400px;right:20px;bottom:10px;}
</style>