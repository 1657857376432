<template>
    <div>
        <title-data :TitleInfo="TitleInfo" @ChartDateType="ChartDateType"></title-data>
        <div class="echarts-main" id="SmoothedLineChart"></div>
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//图表
import * as echarts from 'echarts'
//调用接口
import { allEvent } from '../../api/CqBiApi'

export default{
    components: {
        TitleData
    },
    data() {
        return {
            TitleInfo:{title:'累计事件',type:1,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
            queryData:'month',
        }
    },
    created() {
        
    },
    mounted() {
        this.SmoothedLineChart = echarts.init(document.getElementById('SmoothedLineChart'), null, {renderer: 'svg'});
        this.get_all_event()
    },
    methods: {
        get_all_event(){
            allEvent({
                data: {
                    tab:this.queryData
                }
            }).then(res => {
                if(res.code == 1){
                    let datas = res.data
                    let nums = []
                    let times = []
                    for(var i = 0; i < datas.length; i++){
                        nums.push(datas[i].num)
                        times.push(datas[i].xaxis)
                    }
                    if(nums && times){
                        this.get_info_echarts(nums,times)
                    }
                }
            })
        },
        get_info_echarts(nums,times){
            let option = {
                color:['#fe9c1a'],
                tooltip: {trigger: 'axis'},
                xAxis: {type: 'category',boundaryGap: false,axisLabel: {color: "#8a9ecf",show: true},data: times},
                yAxis: {type: 'value',name: '件',splitLine: {show:true,lineStyle:{color: ['#10427d']}},nameTextStyle: {color: '#8a9ecf'},axisLabel: {color: "#8a9ecf",show: true},},
                grid: {top: '35px',left: '10px',right: '10px',bottom: '15px',containLabel: true},
                series: [{type: 'line',name:'事件总数',smooth: true,data: nums}]
            };
            option && this.SmoothedLineChart.setOption(option);
        },
        //切换时间选项的回调方法
        ChartDateType(info){
            this.queryData = info.typeName
            this.get_all_event()
        }
    }
}
</script>

<style scoped>
.echarts-main{height:190px;margin:5px 10px;}
</style>