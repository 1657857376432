<template>
    <div>
        <title-data :TitleInfo="TitleInfo"></title-data>
        <div class="echarts-main" id="GradientStackedAreaChart"></div>
    </div>
</template>

<script>
//标题与时间组件
import TitleData from "../../components/TitleData/index.vue";
//图表
import * as echarts from 'echarts'
//调用接口
import { userOnline } from '../../api/CqBiApi'

export default{
    components: {
        TitleData
    },
    data() {
        return {
            TitleInfo:{title:'用户实时在线情况',type:0,value:1},  //需要时间切换功能{title:标题，type:1为需要，0为不需要，value：默认选中值}
        }
    },
    created() {
        
    },
    mounted() {
        this.GradientStackedAreaChart = echarts.init(document.getElementById('GradientStackedAreaChart'), null, {renderer: 'svg'});
        this.get_user_online()
    },
    methods: {
        get_user_online(){
            userOnline().then(res => {
                if(res.code == 1){
                    let datas = res.data
                    let times = []
                    let nums = []
                    for(var i = 0; i < datas.length; i++){
                        times.push(datas[i].time)
                        nums.push(datas[i].num)
                    }
                    if(times && nums){
                        this.get_info_echarts(times,nums)
                    }
                }
            })
            let that = this
            setTimeout(function(){
                that.get_user_online()
            },3000)
        },
        get_info_echarts(times,nums){
            let option = {
                tooltip: {trigger: 'axis'},
                grid: {top: '35px',left: '10px',right: '10px',bottom: '15px',containLabel: true},
                xAxis: [{type: 'category',boundaryGap: false,axisLabel: {color: "#8a9ecf",show: true},data: times}],
                yAxis: [{name: '人',nameTextStyle: {color: '#8a9ecf'},axisLabel: {color: "#8a9ecf",show: true},splitLine: {show:true,lineStyle:{color: ['#10427d']}}}],
                series: [{
                    name: '实时在线',
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    lineStyle: {color:'#3fb7f4',width: 2},
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: 'rgb(51, 149, 209)'},{offset: 1,color: 'rgb(6, 32, 82)'}])
                    },
                    emphasis: {focus: 'series'},
                    data: nums
                }]
            }
            option && this.GradientStackedAreaChart.setOption(option);
        },
        
    }
}
</script>

<style scoped>
.echarts-main{height:190px;margin:5px 10px;}
</style>