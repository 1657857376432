<template>
    <div :class="pageState?'map_bg':''">
        <div id="MapDom" class="chinaMap" @click="getType('all')" style="height:470px;margin:0;padding:0;width: 100%;"></div>
        <div v-if="pageState" class="explain_main">
            <div class="explain_box">
                <div style="flex:.5" @click="getType('core')"><span class="color_block color_bg1"></span>核心客户</div>
                <div style="flex:.5" @click="getType('channel')"><span class="color_block color_bg2"></span>渠道商</div>
            </div>
            <div class="explain_box">
                <div style="flex:.5" @click="getType('business')"><span class="color_block color_bg3"></span>小B端客户</div>
                <div style="flex:.5" @click="getType('reseller')"><span class="color_block color_bg4"></span>经销商</div>
            </div>
            <div class="explain_box">
                <div style="flex:.5" @click="getType('customer')"><span class="color_block color_bg5"></span>C端客户</div>
                <div style="flex:.5" @click="getType('distribute')"><span class="color_block color_bg6"></span>分销商</div>
            </div>
        </div>
    </div>
</template>

<script>
//图表（地图图表）
import * as echarts from 'echarts'
//调用中国地图数据
import chinaJson from './china.json'
//调用接口
import { mapCustomer } from '../../api/CqBiApi'

export default {
    data() {
        return {
            pageState:false,
            seriesList:[],
            dataList:[],
            dotColor:{core:'#ffdf00',channel:'#8fff68',business:'#00a3f0',reseller:'#cb4eff',customer:'#ff652a',distribute:'#15fdff'}
        }
    },
    created() {
        
    },
    mounted() {
        this.MapDom = echarts.init(document.getElementById('MapDom'), null, {renderer: 'svg'})
        echarts.registerMap('china', chinaJson) //注册可用的地图
        this.get_map_info()
    },
    methods: {
        get_map_info(){
            mapCustomer().then(res => {
                if(res.code == 1){
                    this.pageState = true
                    this.dataList = res.data
                    var chinaDatas = []
                    for(var i = 0; i < this.dataList.length; i++){
                        chinaDatas.push([this.dataList[i]])
                    }
                    this.get_series_list(chinaDatas)
                }
            })
        },
        get_series_list(chinaDatas){
            let that = this
            this.seriesList = []
            var series = [];
            chinaDatas.forEach(function (item) {
                series.push(
                    {
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        itemStyle: {show: false,color: that.dotColor[item[0].type]},
                        symbolSize: 3,
                        data: item.map(function (dataItem) {
                            return {
                                value: [dataItem.longitude,dataItem.latitude]
                            }
                        })
                    }
                )
            })
            this.seriesList = series
            this.get_china_map_echarts()
        },
        get_china_map_echarts(){
            var option = {
                tooltip: {trigger: 'item',backgroundColor: '#208efb',borderColor: '#FFFFCC',textStyle: {color: "#FFFFCC"},formatter: function () {return ''},},
                geo: {
                    show: true,
                    center: [111.594115019531, 35.182111640625],
                    map: 'china',
                    roam: true, //是否允许缩放，拖拽
                    zoom: 1.5, //初始化大小
                    scaleLimit: {min: 1.5,max: 12},//缩放大小限制
                    itemStyle: {borderWidth: '1',shadowColor: '#218cf9',shadowOffsetX: 0,shadowOffsetY: 5,areaColor: '#090a36'},
                    //高亮状态
                    emphasis: {itemStyle: {areaColor: '#208efb'},label: {show:false}},
                },
                //配置属性
                series: this.seriesList,
            }
            option && this.MapDom.setOption(option)
        },
        getType(type){
            return type;
            // var chinaDatas = []
            // if(type == 'all'){
            //     for(var i = 0; i < this.dataList.length; i++){
            //         chinaDatas.push([this.dataList[i]])
            //     }
            // }else{
            //     for(var ii = 0; ii < this.dataList.length; ii++){
            //         if(this.dataList[ii].type == type){
            //             chinaDatas.push([this.dataList[ii]])
            //         }
            //     }
            // }
            // this.get_series_list(chinaDatas)
        }
    }
}
</script>

<style scoped>
.map_bg{width:100%;height:480px;position: relative;background:url(../../assets/images/map_bg.png) no-repeat 39% 100%;background-size:46%;}
/* .chinaMap {
    transform: rotate3d(1, 0, 0, 35deg);
} */
.explain_main{width:200px;height:90px;position: absolute;left:30px;bottom:25px;}
.explain_box{display:flex;color:#ffffff;font-size:14px;margin-bottom:10px;}
.color_block{width:12px;height:12px;display:inline-block;border-radius: 50%;margin-right:5px;}
.color_bg1{background:#ffdf00}
.color_bg2{background:#8fff68}
.color_bg3{background:#00a3f0}
.color_bg4{background:#cb4eff}
.color_bg5{background:#ff652a}
.color_bg6{background:#15fdff}
</style>